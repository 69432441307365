import { Grid } from "@mui/material";
import { ReactNode } from "react";
import LeftContainer from "src/components/pages/auth/shared/leftContainer";

interface IProps {
  children: ReactNode;
}
const AuthLayout: React.FC<IProps> = ({ children }) => {
  return (
    <Grid container>
      <Grid
        item
        md={6}
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <LeftContainer />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
