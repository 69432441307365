/* eslint-disable import/no-extraneous-dependencies */
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import meSlice from "./reducers/meSlice";
import drawerSlice from "./reducers/drawerSlice";
import notificaitonSlice from "./reducers/notificaitonSlice";

const reducers: any = combineReducers({
  me: meSlice,
  drawer: drawerSlice,
  notification: notificaitonSlice,
});

const store = configureStore({
  reducer: reducers,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store };
