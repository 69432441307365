/* eslint-disable import/no-duplicates */
/* eslint-disable prettier/prettier */
// import { makeStyles } from "tss-react/mui";
import { useState, useEffect } from "react";
import background from "src/assets/images/auth/Backgraound.svg";
import { ReactNode } from "react";

// const useStyles = makeStyles()((theme) => ({
//     root: {
//         [theme.breakpoints.down("md")]: {
//             maxWidth: "50%",
//             position: "fixed",
//         },
//         [theme.breakpoints.down("sm")]: {
//             maxWidth: "50%",
//             position: "fixed",
//         },

//         [theme.breakpoints.up("md")]: {
//             width: "720px",
//             position: "fixed",
//         },
//         // laptop
//         [theme.breakpoints.up("md")]: {
//             width: "50%",
//             position: "fixed",
//         },
//         // extra large
//         [theme.breakpoints.up("xl")]: {
//             maxWidth: "100%",
//             width: "50%",
//         },
//         alignItems: "center",
//         height: "100vh",
//         // Remove background property here
//     },
// }));
interface IProps {
    children: ReactNode;
}
const PreloadedBackground: React.FC<IProps> = ({ children }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageLoaded(true);
        };
        img.src = background;

        // Cleanup function
        return () => {
            img.onload = null;
        };
    }, []);

    return (
        <div
            style={{
                backgroundImage: `url(${background})`,
                opacity: imageLoaded ? 1 : 0, // Show the image when it's loaded
                transition: "opacity 0.5s ease-in-out", // Add a smooth transition
            }}
        >
            {children}
        </div>
    );
};

export default PreloadedBackground;
