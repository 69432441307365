import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  bottomNavAction: {
    margin: 2,
    padding: 0,
    maxWidth: 100,
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bottomNavDif: {
    marginRight: 2,
    marginLeft: -4,
    padding: 0,
    maxWidth: 100,
  },
  bottomNav: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mainBox: {
    borderRadius: "10px",
    height: "calc(100vh - 110px)",
    flex: "1",
    overflowY: "auto",
    overflowX: "hidden",
  },
  gridMain: {
    flex: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 6,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 6,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
    // marginBottom: { xs: 6, sm: 6, md: 6, lg: 0 },
  },
  gridHeader: {
    flex: "0 0 80px",
    position: "relative",
  },
  gridg: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "15px",
  },
  box: {
    display: "flex",
    maxHeight: "100vh",
  },
  grid: {
    flex: "0 0 65px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  spin: {
    height: "100vh",
    width: "100wh",
    backgroundColor: "#e6d9ec",
    color: theme.palette.primary.main,
  },
}));
export default useStyles;
