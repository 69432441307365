import { gql } from "@apollo/client";

const ADDORUPDATENOTIFICATION = gql`
  mutation addOrUpdateNotification(
    $customerId: String!
    $pageId: String!
    $read: Boolean
    $dateandtime: String
    $customerName: String
    $customerPicture: String
    $update: Boolean
  ) {
    addOrUpdateNotification(
      customerId: $customerId
      pageId: $pageId
      read: $read
      dateandtime: $dateandtime
      customerName: $customerName
      customerPicture: $customerPicture
      update: $update
    ) {
      success
      error
      result
    }
  }
`;

export default ADDORUPDATENOTIFICATION;
