import { gql } from "@apollo/client";

const GETNOTIFICATIONS = gql`
  query getNotifications($customerId: String, $pageId: String) {
    getNotifications(customerId: $customerId, pageId: $pageId) {
      success
      error
      result
    }
  }
`;

export default GETNOTIFICATIONS;
