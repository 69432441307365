import { Backdrop, CircularProgress } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import { Navigate } from "react-router-dom";
import useStyles from "./Styles";

const Spinner = () => {
  const { classes } = useStyles();
  const { isAuthenticated } = useAuth() as any;
  if (isAuthenticated) {
    return <Navigate to="/chats" />;
  }
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="primary" />
      {/* <div>Loading...</div> */}
    </Backdrop>
  );
};
export default Spinner;
