import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import theme from "src/theme";
import { store } from "./store/store";
import apolloConfig from "./config/apolloupdate";
import Layout from "./layout/Layout";
import AuthGuard from "./components/hoc/AuthGuard";
import GuestGuard from "./components/hoc/GuestGuard";
import "./App.css";
import SpinnerLoader from "./components/shared/atoms/SpinnerLoader";

// Lazy-loaded components
const Login = React.lazy(() => import("src/pages/auth/login/Login"));
const Signup = React.lazy(() => import("src/pages/auth/signup/Signup"));
const AuthForgetPassword = React.lazy(
  () => import("src/pages/auth/ForgetPassword"),
);
const AuthEmailConfirm = React.lazy(
  () => import("src/pages/auth/EmailConfirm"),
);
const InviteUser = React.lazy(() => import("src/pages/auth/InviteUser"));
const ResetPassword = React.lazy(() => import("src/pages/auth/resetPassword"));
const Facebook = React.lazy(
  () => import("src/components/pages/settings/rightPanel/channels/facebook"),
);
const CannedResponses = React.lazy(
  () => import("src/components/pages/settings/rightPanel/canned"),
);
const NotSelected = React.lazy(
  () => import("src/components/pages/settings/rightPanel/notSelected"),
);
const Twilio = React.lazy(
  () => import("src/components/pages/settings/rightPanel/channels/twilio"),
);
const Label = React.lazy(
  () => import("src/components/pages/settings/rightPanel/labels"),
);
const ProfileSettings = React.lazy(
  () => import("src/components/pages/settings/rightPanel/profileSetting"),
);
const Dashboard = React.lazy(() => import("src/pages/dashboard"));
const Users = React.lazy(() => import("src/pages/users"));
const Reports = React.lazy(() => import("src/pages/reports"));
const Chat = React.lazy(() => import("src/pages/chat"));
const Setting = React.lazy(() => import("src/pages/settings"));
const Privacy = React.lazy(() => import("./pages/auth/privacy"));

const App = () => {
  const { apolloClient, wsLink } = apolloConfig();

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<SpinnerLoader />}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route element={<GuestGuard />}>
                  <Route path="login" element={<Login />} />
                  <Route path="signup" element={<Signup />} />
                  <Route
                    path="forgetpassword"
                    element={<AuthForgetPassword />}
                  />
                  <Route path="confirm" element={<AuthEmailConfirm />} />
                  <Route
                    path="/join/:email/:designation/:superAdminId/:token"
                    element={<InviteUser />}
                  />
                  <Route
                    path="/join/:designation/:superAdminId/:token"
                    element={<InviteUser />}
                  />
                  <Route
                    path="/join/:email/:designation/:superAdminId/:managerId/:token"
                    element={<InviteUser />}
                  />
                  <Route
                    path="/resetpassword/:token/:email"
                    element={<ResetPassword />}
                  />
                </Route>
                <Route path="privacypolicy" element={<Privacy />} />
                <Route element={<AuthGuard wsLink={wsLink} />}>
                  <Route path="" element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="chats" element={<Chat />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="/settings" element={<Setting />}>
                    <Route path="" element={<NotSelected />} />
                    <Route path="facebook" element={<Facebook />} />
                    <Route path="twilio" element={<Twilio />} />
                    <Route path="label" element={<Label />} />
                    <Route
                      path="canned-response"
                      element={<CannedResponses />}
                    />
                    <Route path="profile" element={<ProfileSettings />} />
                  </Route>
                </Route>
                <Route path="*" element={<Login />} />
              </Route>
            </Routes>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
