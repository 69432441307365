import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import getHeaderText from "src/utils/layout/useGetHeaderText";
import useStyles from "src/components/layout/mainLayout/header/Styles";
import Appbar from "src/components/layout/mainLayout/header/appbar";
import Notification from "./notification";

const Header: React.FC = () => {
  const location = useLocation();
  const { heading, text } = getHeaderText(location.pathname);
  const notification = location.pathname.includes("/chats");
  const { classes } = useStyles();
  return (
    <Appbar>
      <Grid container className={classes.header}>
        <Grid item>
          <Typography className={classes.typography}>{heading}</Typography>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>
        <Grid item>{notification && <Notification />}</Grid>
      </Grid>
    </Appbar>
  );
};

export default Header;
