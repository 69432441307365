/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import { useTheme } from "@mui/material/styles";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CssBaseline,
  Grid,
  Paper,
} from "@mui/material";
import { useSnackbar } from "notistack";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import { useMutation } from "@apollo/client";
import expressConfig from "src/config/express.json";
import HomeIcon from "@mui/icons-material/Home";
import Header from "src/components/layout/mainLayout/header";
import NavigationList from "src/components/layout/mainLayout/navigationList";
// import LoaderSpinner from "src/components/shared/atoms/SpinnerLoader";
import { useState, useEffect, type FC, type ReactNode } from "react";
import Main from "src/components/layout/mainLayout/main/Main";
import useNetwork from "src/hooks/useNetwork";
import { useNavigate } from "react-router-dom";
// import { publicIp } from "public-ip";
import ProfileBottom from "src/components/layout/mainLayout/navigationList/profile";
import SettingsIcon from "@mui/icons-material/Settings";
// import LoaderSpinner from "src/components/shared/atoms/SpinnerLoader";
import LogoutQuery from "src/graphql/query/auth/logout";
import useStyles from "./Styles";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isOnline = useNetwork();
  const navigate = useNavigate();
  const [prevIsOnline, setPrevIsOnline] = useState(false);
  const [value, setValue] = useState("/");
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const env = process.env.NODE_ENV || "development";
  const config = expressConfig[env];

  useEffect((): any => {
    if (!isOnline) {
      enqueueSnackbar("Internet connection lost. Please check your network.", {
        variant: "error",
      });
    } else if (isOnline && prevIsOnline) {
      enqueueSnackbar("Internet connection recovered.", {
        variant: "success",
      });
    }
  }, [isOnline, prevIsOnline, enqueueSnackbar]);

  useEffect(() => {
    if (!isOnline) setPrevIsOnline(true);
  }, [isOnline]);

  const [logout, { data: logoutQueryResult }] = useMutation(LogoutQuery);
  const handleLogout = async () => {
    await logout();
  };

  // const fetchIpAddress = async () => {
  //   try {
  //     const response = await publicIp();
  //     const res = response.split(".");
  //     res.pop();
  //     const ipNetworkPart = res.join(".");
  //     // if (ipNetworkPart !== config.ACCESSABLE_IP_RANGE) {
  //     //   enqueueSnackbar("Ip has changed.", {
  //     //     variant: "error",
  //     //   });
  //     //   await handleLogout();
  //     // }
  //   } catch (error) {
  //     console.error("Error fetching IP address:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchIpAddress();
  //   const interval = setInterval(fetchIpAddress, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (logoutQueryResult && logoutQueryResult.logout.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("ActiveUserdetail");
      localStorage.removeItem("userName");
      localStorage.removeItem("persist:root");
      window.location.href = "/login";
    }
  }, [logoutQueryResult]);

  return (
    <Box className={classes.box}>
      <CssBaseline />
      <Grid className={classes.grid}>
        <NavigationList />
      </Grid>
      <Grid className={classes.gridg}>
        <Grid className={classes.gridHeader}>
          {" "}
          <Header />
        </Grid>
        <Grid className={classes.gridMain}>
          <Main theme={theme}>
            <Box className={classes.mainBox}>{children}</Box>
          </Main>
        </Grid>
        <Paper className={classes.paper} elevation={3}>
          <BottomNavigation
            className={classes.bottomNav}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              navigate(`${newValue}`);
            }}
          >
            <BottomNavigationAction
              className={classes.bottomNavDif}
              value="/"
              label="Dashboard"
              icon={<HomeIcon />}
            />
            <BottomNavigationAction
              className={(classes.bottomNavAction, classes.hide)}
              value="/chats"
              label="Chats"
              icon={<ForumIcon />}
            />
            <BottomNavigationAction
              className={classes.bottomNavAction}
              value="/reports"
              label="Reports"
              icon={<DescriptionIcon />}
            />
            <BottomNavigationAction
              className={(classes.bottomNavAction, classes.hide)}
              value="/settings"
              label="Settings"
              icon={<SettingsIcon />}
            />
            <ProfileBottom />
          </BottomNavigation>
        </Paper>
      </Grid>
    </Box>
  );
};

export default MainLayout;
