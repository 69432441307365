import { Box } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import LoaderSpinner from "src/components/shared/atoms/SpinnerLoader";

import useAuth from "src/hooks/useAuth";
import AuthLayout from "src/layout/AuthLayout";
// import AuthLayout from "src/layout/AuthLayout";

const GuestGuard = () => {
  const { loading, isAuthenticated } = useAuth();

  if (loading) {
    <Box
      style={{
        height: "100vh",
        width: "100wh",
        backgroundColor: "#e6d9ec",
      }}
    >
      <LoaderSpinner />
    </Box>;
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
};

export default GuestGuard;
